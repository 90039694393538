import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { loginByAccessKey, setSessionToken } from "../../utils/api";
import CustomerPortalAuthProcess from "./CustomerPortalAuthProcess";
import { DashboardContext } from "../../context/DashboardContext";

function Auth() {
    const [authenticated, setAuthenticated] = useState(false);
    const [error, setError] = useState(false);

    const context = useContext(DashboardContext);
    const [accessKey] = useState(useParams().accessKey);
    const history = useHistory();

    const authenticateWithCustomerPortal = async () => {
        try {
            setAuthenticated(false);
            await loginByAccessKey(accessKey);

            setTimeout(function () {
                setAuthenticated(true);
                setTimeout(function () {
                    context.updateLoggedIn(true);
                    setSessionToken(sessionStorage.token);
                    history.push('/job-readiness');
                    history.go(0);
                }, 2500);
            }, 1000);

        } catch (err) {
            setError(true);
            setAuthenticated(false);
            context.updateLoggedIn(false);
        }
    };

    useEffect(() => {
        authenticateWithCustomerPortal();
    }, []);

    return <CustomerPortalAuthProcess authenticated={authenticated} error={error}/>;
}

export default Auth;
